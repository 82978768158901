<template>
	<div class="looping-selector" v-observe-visibility="visibilityChanged">
		<div class="scroll-viewer" @scroll="(event) => _onScroll(event)" @wheel="(event) => _onWheel(event)" ref="scroll-viewer">
			<div class="looping-selector-item" v-for="(item, index) in items" :key="index">
				<slot name="template" :local="item" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoopingSelector",
	props: {
		items: null,
		shouldLoop: {
			type: Boolean,
			default: true
		},
		value: null
	},
	data() {
		return {
			_previousScrollTop: 0,
			_scrollStoppedTimeout: null
		}
	},
	mounted() {
		this.createDuplicates();
		this.scrollToValue();

		this.$refs["scroll-viewer"].addEventListener("DOMMouseScroll", this._onWheel);
	},
	updated() {
		this.createDuplicates();
		this.scrollToValue();
	},
	methods: {
		_onScroll(event) {
			event.preventDefault();

			let vm = this;
			let _index, _calculatedValue;

			if (this.shouldLoop && Object.values(this.items).length >= 5) {
				var scrollDirection = Math.sign(event.target.scrollTop - this._previousScrollTop) || 0;

				// if (event.target.scrollTop > this._previousScrollTop) {
				// 	scrollDirection = 1;
				// } else if (event.target.scrollTop < this._previousScrollTop) {
				// 	scrollDirection = -1;
				// }

				if (event.target.scrollTop + event.target.clientHeight > event.target.scrollHeight - 40 && scrollDirection == 1) {
					event.target.scrollTop = (event.target.scrollTop - ((event.target.scrollHeight - event.target.clientHeight) - 40));
				}

				if (event.target.scrollTop < 40 && scrollDirection == -1) {
					event.target.scrollTop = (event.target.scrollHeight - event.target.clientHeight) - (40 - event.target.scrollTop)
				}

				// this._previousScrollTop = event.target.scrollTop;
				_index = ((event.target.scrollTop - 40) / 40) >> 0;
			} else {
				_index = (event.target.scrollTop / 40) >> 0;
			}

			this._previousScrollTop = event.target.scrollTop;

			if (this.items instanceof Array) {
				_calculatedValue = this.items[_index];
			} else {
				_calculatedValue = Object.keys(this.items)[_index];
			}

			clearTimeout(this._scrollStoppedTimeout);
			this._scrollStoppedTimeout = setTimeout(function() {
				vm.$emit("input", _calculatedValue);
			}, 200);
		},
		_onWheel(event) {
			event.preventDefault();

			if (event.detail) {
				this.$refs["scroll-viewer"].scrollTop += 40 * (event.detail / 3);
			} else {
				this.$refs["scroll-viewer"].scrollTop += 40 * Math.sign(event.deltaY);
			}

			return false
		},

		createDuplicates() {
			let scrollViewer = this.$refs["scroll-viewer"];

			scrollViewer.querySelectorAll(".looping-selector-item.blind-item").forEach(node => {
				node.parentNode.removeChild(node);
			});

			let children = Array.from(scrollViewer.children);

			if (this.shouldLoop && Object.values(this.items).length >= 5) {
				children.slice(Math.max(children.length - 5, 0)).reverse().forEach(child => {
					let node = child.cloneNode(true);
					node.classList.add("blind-item");
					scrollViewer.prepend(node);
				});

				children.slice(0, 5).forEach(child => {
					let node = child.cloneNode(true);
					node.classList.add("blind-item");
					scrollViewer.appendChild(node);
				});
			} else {
				let node = document.createElement("div");
				node.className = "looping-selector-item blind-item";

				for (var i = 0; i < 4; i++) {
					scrollViewer.prepend(node.cloneNode(true));
					scrollViewer.appendChild(node.cloneNode(true));
				}
			}
		},
		scrollToValue() {
			let scrollViewer = this.$refs["scroll-viewer"];

			if (this.shouldLoop && Object.values(this.items).length >= 5) {
				if (this.items instanceof Array) {
					scrollViewer.scrollTop = 40 * (Math.max(this.items.indexOf(this.value), 0) + 1);
				} else {
					scrollViewer.scrollTop = 40 * (Math.max(Object.keys(this.items).indexOf(this.value.toString()), 0) + 1);
				}
			} else {
				if (this.items instanceof Array) {
					scrollViewer.scrollTop = 40 * Math.max(this.items.indexOf(this.value), 0);
				} else {
					scrollViewer.scrollTop = 40 * Math.max(Object.keys(this.items).indexOf(this.value.toString()), 0);
				}
			}
		},
		visibilityChanged(visible) {
			if (visible) this.scrollToValue();
		}
	}
}
</script>

<style lang="less">
.looping-selector {
	position: relative;
	height: 360px;

	.scroll-viewer {
		width: 100%;
		height: 100%;
		overflow: scroll;
		scrollbar-width: none;
		scroll-snap-type: y mandatory;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.looping-selector-item {
		width: 100%;
		height: 40px;
		padding: 9px 9px 12px;
		scroll-snap-align: start;
	}
}
</style>