import { StatusAPI } from '@/scripts/ApiUtil'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
	name: "Events",
	components: {
		DateTimePicker
	},
	data() {
		return {
			date: new Date(),
			events: null,
			services: [],

			newEvent: {
				type: "",
				startDate: null,
				endDate: null,
				resolved: false,
				affectedServices: []
			}
		}
	},
	beforeRouteEnter: async (to, from, next) => {
		let _services = await StatusAPI.getServices();
		let _events = await StatusAPI.getEvents();

		next(vm => {
			vm.services = _services;
			vm.events = _events;
		});
	},
	methods: {
		async createEvent() {
			let result = await StatusAPI.createEvent(this.newEvent);

			if (result.error) {
				alert(`${result.error.name}\n${result.error.message}`);
			} else {
				this.services = await StatusAPI.getServices();
				this.events = await StatusAPI.getEvents();
			}
		},
		async deleteEvent(eventId) {
			let result = await StatusAPI.deleteEvent(eventId);

			if (result.error) {
				alert(`${result.error.name}\n${result.error.message}`);
			} else {
				this.services = await StatusAPI.getServices();
				this.events = await StatusAPI.getEvents();
			}
		},
		async resolveEvent(event) {
			let result = await StatusAPI.updateEvent(event.id, {
				resolved: true,
				endDate: new Date()
			});

			if (result.error) {
				alert(`${result.error.name}\n${result.error.message}`);
			} else {
				this.services = await StatusAPI.getServices();
				this.events = await StatusAPI.getEvents();
			}
		},
		async updateEvent(event) {
			let result = await StatusAPI.updateEvent(event.id, event);

			if (result.error) {
				alert(`${result.error.name}\n${result.error.message}`);
			} else {
				this.services = await StatusAPI.getServices();
				this.events = await StatusAPI.getEvents();
			}
		},

		eventTime(event) {
			let now = new Date();

			let _startDate = new Date(event.startDate);
			let startTime = _startDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

			if (_startDate.getTime() > now.getTime()) return `${this.$t('status.events.from_date', { date: _startDate.toLocaleDateString(), time: startTime })}`;
			if (!event.endDate && !event.resolved) return `${this.$t('status.events.since_date', { date: _startDate.toLocaleDateString(), time: startTime })}`;
			if (!event.endDate && event.resolved) return `${_startDate.toLocaleDateString()}, ${startTime}`;

			let _endDate = new Date(event.endDate);
			let endTime = _endDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

			return `${_startDate.toLocaleDateString()}, ${startTime} – ${_endDate.toLocaleDateString()}, ${endTime}`;
		},
		serviceNamesForEvent(event) {
			let services = event.affectedServices.map(service => this.serviceNameForUuid(service)).filter(Boolean);

			if (!services.length) return null;
			if (services.length == event.affectedServices.length) {
				switch (services.length) {
					case 1:
						return services[0];
					case 2:
						return services.join(", ");
					default:
						return `${[...services].splice(0,2).join(", ")} ${this.$tc('events.more_events', event.affectedServices.length - [...services].splice(0,2).length)}`
				}
			} else {
				return `${[...services].splice(0,2).join(", ")} ${this.$tc('events.more_events', event.affectedServices.length - [...services].splice(0,2).length)}`
			}
		},
		serviceNameForUuid(uuid) {
			let service = this.services.find(_ => _.uuid === uuid);

			if (!service) return null;
			return service.name;
		}
	}
}