<template>
	<div class="date-picker" :disabled="disabled">
		<label v-if="header">{{ header }}</label>

		<div class="date-picker-content" @click="openFlyout" ref="content">
			<div class="d-flex flex-justify-center">
				<p class="day-text-block text-center" :class="{'date-picker-placeholder': value == null}">{{ dayString }}</p>
				<p class="month-text-block" :class="{'date-picker-placeholder': value == null}">{{ monthString }}</p>
				<p class="year-text-block text-center" :class="{'date-picker-placeholder': value == null}">{{ yearString }}</p>

				<p class="text-center hour-text-block" :class="{'date-picker-placeholder': value == null}">{{ hourString }}</p>
				<p class="text-center minute-text-block" :class="{'date-picker-placeholder': value == null}">{{ minuteString }}</p>
				<p class="text-center second-text-block" :class="{'date-picker-placeholder': value == null}" v-if="showSeconds">{{ secondString }}</p>

				<p class="text-center period-text-block" :class="{'date-picker-placeholder': value == null}" v-if="!uses24HourClock">{{ !isPM ? "AM" : "PM" }}</p>
			</div>

			<div class="date-picker-flyout" :class="{'show': flyoutOpen}">
				<div class="d-flex flex-column">
					<div class="d-flex" style="padding 0 2px">
						<div class="highlight-rect" />

						<LoopingSelector class="day-looping-selector" :items="daysInMonth" :key="`day_${day}`" v-model="day">
							<template slot="template" slot-scope="{ local }">
								<p class="text-center">{{ local }}</p>
							</template>
						</LoopingSelector>

						<LoopingSelector class="month-looping-selector" :items="monthsInYear" :key="`month_${month}`" v-model="month">
							<template slot="template" slot-scope="{ local }">
								<p>{{ local }}</p>
							</template>
						</LoopingSelector>

						<LoopingSelector class="year-looping-selector" :items="years" :key="`year_${year}`" v-model="year" :should-loop="false">
							<template slot="template" slot-scope="{ local }">
								<p class="text-center">{{ local }}</p>
							</template>
						</LoopingSelector>

						<LoopingSelector class="hour-looping-selector" :items="hours" :key="`hour_${hour}`" v-model="hour">
							<template slot="template" slot-scope="{ local }">
								<p class="text-center">{{ local }}</p>
							</template>
						</LoopingSelector>

						<LoopingSelector class="minute-looping-selector" :items="minutes" :key="`minute_${minute}`" v-model="minute">
							<template slot="template" slot-scope="{ local }">
								<p class="text-center">{{ local }}</p>
							</template>
						</LoopingSelector>

						<LoopingSelector class="second-looping-selector" :items="minutes" :key="`second_${second}`" v-model="second" v-if="showSeconds">
							<template slot="template" slot-scope="{ local }">
								<p class="text-center">{{ local }}</p>
							</template>
						</LoopingSelector>

						<LoopingSelector class="period-looping-selector" :items="{'0':'AM','1':'PM'}" :key="`period_${pm}`" v-model="pm" v-if="!uses24HourClock">
							<template slot="template" slot-scope="{ local }">
								<p class="text-center">{{ local }}</p>
							</template>
						</LoopingSelector>
					</div>

					<div class="d-flex">
						<button class="dismiss-button text-danger" @click="dismiss">Cancel</button>
						<button class="accept-button text-primary" @click="accept">OK</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
HTMLElement.prototype.parentNodeOfClass = function(className) {
	var node = this.parentNode;
	while (node) {
		if (node.classList && node.classList.contains(className)) {
			return node;
		}
		node = node.parentNode
	}

	return null;
}

import LoopingSelector from "./LoopingSelector.vue"

export default {
	name: "DateTimePicker",
	components: {
		LoopingSelector
	},
	props: {
		header: String,
		disabled: Boolean,
		value: [Date, String],
		clockIdentifier: {
			type: String,
			default: "24HourClock",
			validator: value => {
				return ["12HourClock", "24HourClock"].indexOf(value) >= 0
			}
		},
		showSeconds: Boolean,
		dayPlaceholder: {
			type: String,
			default: "Day"
		},
		monthPlaceholder: {
			type: String,
			default: "Month"
		},
		yearPlaceholder: {
			type: String,
			default: "Year"
		},
		hourPlaceholder: {
			type: String,
			default: "Hour"
		},
		minutePlaceholder: {
			type: String,
			default: "Minute"
		},
		secondPlaceholder: {
			type: String,
			default: "Second"
		}
	},
	data() {
		let formattedDate = (this.value && new Date(this.value)) ? new Date(this.value) : (this.value || new Date());
		let isPM = (parseInt(this.pm) == 1);

		return {
			flyoutOpen: false,

			pm: parseInt(+(this.clockIdentifier == "12HourClock" && formattedDate.getHours() >= 12)),

			day: formattedDate.getDate(),
			month: formattedDate.getMonth() + 1,
			year: formattedDate.getFullYear(),

			hour: formattedDate.getHours() - (isPM ? 12 : 0),
			minute: formattedDate.getMinutes(),
			second: formattedDate.getSeconds(),

			_eventListener: null
		}
	},
	methods: {
		accept() {
			let date = new Date(
				this.year,
				((parseInt(this.month) - 1) + 12) % 12,
				this.day,
				parseInt(this.hour) + (this.isPM ? 12 : 0),
				this.minute,
				this.second
			);

			if (typeof this.value === "string") {
				this.$emit("input", date.toISOString());
			} else {
				this.$emit("input", date);
			}

			this.dismiss();
		},
		dismiss() {
			let vm = this;

			setTimeout(() => {
				vm.flyoutOpen = false;
				document.removeEventListener("click", vm._eventListener, true);

				vm._eventListener = null;

				document.body.style.overflow = null;
			}, 50);
		},
		dismiss_internal(event) {
			if (!event.target.parentNodeOfClass("date-picker-flyout")) {
				event.preventDefault();
				event.stopPropagation();

				this.dismiss();
			}
		},
		openFlyout() {
			if (!this.flyoutOpen) {
				this.day = this.formattedDate.getDate();
				this.month = this.formattedDate.getMonth() + 1;
				this.year = this.formattedDate.getFullYear();

				this.pm = parseInt(+(this.clockIdentifier == "12HourClock" && this.formattedDate.getHours() >= 12));
				this.hour = this.formattedDate.getHours() - (this.isPM ? 12 : 0),
				this.minute = this.formattedDate.getMinutes();
				this.second = this.formattedDate.getSeconds();

				this.flyoutOpen = true;

				this._eventListener = this.dismiss_internal.bind(this);

				document.addEventListener("click", this._eventListener, true);
				document.body.style.overflow = "hidden";
			}
		},
		nameOfMonth(_month) {
			return new Date(this.year, _month, 0).toLocaleDateString(undefined, { month: "long" });
		}
	},
	computed: {
		formattedDate() {
			return (this.value && new Date(this.value)) ? new Date(this.value) : (this.value || new Date());
		},

		dayString() {
			if (!this.value) return this.dayPlaceholder;

			return this.formattedDate.getDate();
		},
		monthString() {
			if (!this.value) return this.monthPlaceholder;

			return this.formattedDate.toLocaleDateString(undefined, { month: "long" });
		},
		yearString() {
			if (!this.value) return this.yearPlaceholder;

			return this.formattedDate.getFullYear();
		},

		hourString() {
			if (!this.value) return this.hourPlaceholder;

			return this.formattedDate.getHours() - (this.isPM ? 12 : 0);
		},
		minuteString() {
			if (!this.value) return this.minutePlaceholder;

			return `${this.formattedDate.getMinutes()}`.padStart(2, "0")
		},
		secondString() {
			if (!this.value) return this.secondPlaceholder;

			return `${this.formattedDate.getSeconds()}`.padStart(2, "0");
		},

		daysInMonth() {
			let days = [];
			let _dayCount = new Date(this.year, this.month, 0).getDate();

			for (var i = 0; i < _dayCount; i++) {
				days.push(i + 1);
			}

			return days;
		},
		monthsInYear() {
			let months = {};

			for (var i = 0; i < 12; i++) {
				months[i] = this.nameOfMonth(i);
			}

			return months;
		},
		years() {
			let _year = new Date().getFullYear();
			let years = [_year];

			for (var i = 1; i <= 50; i++) {
				years.push(_year + i);
				years.unshift(_year - i);
			}

			return years;
		},
		hours() {
			let hours = {};

			if (this.uses24HourClock) {
				for (var i = 0; i < 24; i++) {
					hours[i] = `${i}`;
				}
			} else {
				for (var i = 1; i <= 12; i++) {
					hours[i] = `${i}`;
				}
			}

			return hours;
		},
		minutes() {
			let minutes = {};

			for (var i = 0; i < 60; i++) {
				minutes[i] = `${i}`.padStart(2, "0");
			}

			return minutes;
		},

		minimumYear() {
			return new Date().getFullYear() - 100;
		},
		maximumYear() {
			return new Date().getFullYear() + 100;
		},

		uses24HourClock() {
			return this.clockIdentifier == "24HourClock";
		},
		isPM() {
			return parseInt(this.pm) == 1;
		},
	}
}
</script>

<style lang="less">
.date-picker {
	label {
		display: block;
		margin-bottom: 4px;
	}

	.date-picker-content {
		position: relative;
		display: inline-flex;
		// width: 292px;
		padding: 2px;
		box-shadow: inset 0 0 0 1px var(--separator-color);
		border-radius: 6px;
		cursor: default;

		&:active {
			background-color: var(--tertiary-system-background-color);
		}

		.date-picker-placeholder {
			color: var(--placeholder-text-color);
		}

		.day-text-block,
		.year-text-block,
		.hour-text-block,
		.minute-text-block,
		.second-text-block,
		.period-text-block {
			min-width: 78px;
			margin: 0;
			padding: 6px 9px;
		}

		.month-text-block {
			min-width: 132px;
			margin: 0;
			padding: 6px 9px;
		}

		.month-text-block,
		.year-text-block,
		.hour-text-block,
		.minute-text-block,
		.second-text-block,
		.period-text-block {
			border-left: 1px solid var(--separator-color);
		}
	}

	.date-picker-flyout {
		display: inline-flex;
		position: absolute;
		top: -161px;
		left: 0;
		background-color: var(--tertiary-system-background-color);
		border-radius: 6px;
		overflow: hidden;
		z-index: 9999;

		&:not(.show) {
			// visibility: hidden;
			display: none;
		}

		& > .d-flex {
			width: 100%;
		}

		.highlight-rect {
			position: absolute;
			top: 160px;
			left: 0;
			width: 100%;
			height: 40px;
			background-color: #FFFFFF11;
			pointer-events: none;
		}

		.day-looping-selector,
		.year-looping-selector,
		.hour-looping-selector,
		.minute-looping-selector,
		.second-looping-selector,
		.period-looping-selector{
			width: 78px;
		}

		.month-looping-selector {
			width: 132px;
		}

		.month-looping-selector,
		.year-looping-selector,
		.hour-looping-selector,
		.minute-looping-selector,
		.second-looping-selector,
		.period-looping-selector {
			border-left: 1px solid var(--separator-color);
		}

		.accept-button,
		.dismiss-button {
			height: 40px;
			flex: 1;
			display: flex;
			justify-content: center;
			box-shadow: none !important;
			border: none;
			border-top: 1px solid var(--separator-color);
			background-color: transparent;
			line-height: 35px;

			&:not(:last-child) {
				border-right: 1px solid var(--separator-color);
			}

			&:hover:not(:active) {
				background-color: #FFFFFF11;
			}

			&:active {
				background-color: #FFFFFF33;
			}

			i.symbol {
				font-size: 16px !important;
			}
		}
	}

	&[disabled] {
		pointer-events: none;

		.date-picker-content {
			color: #FFFFFF66;
		}
	}
}
</style>